import './App.css';
import Header from './Components/Header';
import Home from './Components/Home';
import Footer from './Components/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Features from './Components/Features';

function App() {
return (
	<div className="App">
	<Header/>
	<BrowserRouter>
	<Routes>
		<Route path='/' element={<Home/>}/>
		<Route path='/features' element={<Features/>}/>
	</Routes>

	{/* <Home/> */}
	</BrowserRouter>
	<Footer/>
	</div>
);
}

export default App;
