import React, { useEffect } from 'react'
import { useState } from 'react'
import logo from '../images/dark_images/logo.svg'
import btc from '../images/dark_images/btc.svg'
import fiat from '../images/dark_images/fiatt.svg'
import people from '../images/art 3 1.svg'
import '../App.css'
import './Home.css'
import "@fontsource/inter";
import "@fontsource/inter/500.css"
import coin from '../images/in500 1.svg';
import crypto from '../images/Group 961.svg'
import dollar from '../images/usd f 2.svg'
import dollarsigns from '../images/dollar signs.svg'
import dollaricon from '../images/usd f copyfff 1.svg'
import dollarnote from '../images/dark_images/1.png'
import dollarnoteback from '../images/dollar back final 2 1.svg'
import btcbold from '../images/dark_images/btc_blue.svg'
import ReactFlipCard from 'reactjs-flip-card'
import fiatbold from '../images/dark_images/fiat_blue.svg'
import bitcoin from '../images/bitcoin.svg'
import ethereum from '../images/dark_images/ETH.svg'
// import bitblack from '../images/bit_blk.svg'
// import bitpurple from '../images/bit_purple.svg'
// import bitblue from '../images/bit_blue.svg'
// import bitgreen from '../images/bit_green.svg'
// import bitpink from '../images/bit_pink.svg'
// import bitorange from '../images/bit_orange.svg'
// import bitblackback from '../images/bit_black_back.svg'
// import bitpurpleback from '../images/bit_purple_back.svg'
// import bitblueback from '../images/bit_blue_back.svg'
// import bitgreenback from '../images/bit_green_back.svg'
// import bitpinkback from '../images/bit_pink_back.svg'
// import bitorangeback from '../images/bit_orange_back.svg'

// import ethblack from '../images/eth_black.svg'
// import ethpurple from '../images/eth_purple.svg'
// import ethblue from '../images/eth_blue.svg'
// import ethgreen from '../images/eth_green.svg'
// import ethpink from '../images/eth_pink.svg'
// import ethorange from '../images/eth_orange.svg'

// import ethblackback from '../images/eth_black_back.svg'
// import ethpurpleback from '../images/eth_purple_back.svg'
// import ethblueback from '../images/eth_blue_back.svg'
// import ethgreenback from '../images/eth_green_back.svg'
// import ethpinkback from '../images/eth_pink_back.svg'
// import ethorangeback from '../images/eth_orange_back.svg'
// import { getCryptoPrice } from '../service/api'



const Home = () => {
    const [btcimg, setbtcimg] = useState(btc);
    const [fiatimg, setfiatimg] = useState(fiat);

    // const [bit_page, setBit_page] = useState(false);
    // const [eth_page, setEth_page] = useState(false);

    // const [btcPrice, setBtcPrice] = useState();
    // const [ethPrice, setEthPrice] = useState();
    // const [btcPriceChange, setBTCpriceChange] = useState();
    // const [ethPriceChange, setETHpriceChange] = useState();
    // const [btcPriceChangePer, setBTCpriceChangePer] = useState();
    // const [ethPriceChangePer, setETHpriceChangePer] = useState();

    // useEffect(() => {

    //     async function lastPrice() {
    //         // Define static conversion rates for BTC and ETH
    //         const btcToUsdRate = await getCryptoPrice('BTC'); // Conversion rate for BTC to USD
    //         const ethToUsdRate = await getCryptoPrice('ETH'); // Conversion rate for ETH to USD
    //         setBtcPrice(btcToUsdRate.data.lastPrice);
    //         setEthPrice(ethToUsdRate.data.lastPrice);
    //         setBTCpriceChange(btcToUsdRate.data.priceChange);
    //         setETHpriceChange(ethToUsdRate.data.priceChange);
    //         setBTCpriceChangePer(btcToUsdRate.data.priceChangePercent)
    //         setETHpriceChangePer(ethToUsdRate.data.priceChangePercent);
    //     }
    //     lastPrice();
    // }, [])
    return (
        <div className='page'>
            <div className='centered-div'>
                <div style={{ marginBottom: "25px" }}>
                    <img src={logo} alt="" />
                </div>
                <div className='container text-center'>
                    <div className='row row-cols-2'>
                        <div className='col img-links'>
                            <a href="#crypto-cur" style={{ textDecoration: "none" }}>
                                <img src={btcimg} alt="Cryptocurrency"
                                    //  onMouseOver={e => (e.currentTarget.src={btcbold})}
                                    onMouseOut={() => setbtcimg(btc)}
                                    onMouseEnter={() => setbtcimg(btcbold)}
                                />
                                <p>
                                    Cryptocurrency
                                </p>
                            </a>
                        </div>
                        <div className='col img-links'>
                            <a href="#fiat-cur" style={{ textDecoration: "none" }}>
                                <img src={fiatimg} alt="Fiat Currency"
                                    onMouseOut={() => setfiatimg(fiat)}
                                    onMouseEnter={() => setfiatimg(fiatbold)}
                                />
                                <p>
                                    Fiat Currency
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div style={{height:"100vh", justifyContent: "center", alignContent: "center",display: "grid"}}> */}
            <div className='centered-div'>
                <img src={people} alt="" style={{ width: "98vw" }} />
            </div>
            <div className='arranged'>
                <p className='what'>What is XNFT</p>
                <p className='d-flex text-start justify-content-start content'>
                    The concept of XNFTs involves creating non-fungible tokens (NFTs) that are linked or pegged to various cryptocurrency, other NFTs and real-world assets such as fiat currency, stocks, insurance, commodities in the blockchain.
                    <br /><br />
                    This idea aims to utilize NFT’s unique image and identification feature to represent, manage and trade digital and real-world assets, to bridge the gap between the digital and physical worlds by providing a tokenized representation of tangible or intangible assets on the blockchain.
                    <br /><br />
                    XNFT tokens are tradable on the Indexx trading X-platform as well as on secondary platforms such as Opensea.io. These tokens can be securely stored and held in the Indexx Smart Wallet or in any other compatible cryptocurrency wallet on the blockchain.
                    <br /><br />
                </p>
                {/* </div> */}
            </div>

            <div class="what-crypto" id='crypto-cur'>
                <div>
                    <div class="row">
                        <div class="col-2" style={{ padding: 0 }}></div>
                        <div className='col-10'>
                            <img src={coin} alt="" style={{ marginTop: "-25px" }} />
                            <span className='crypto'>Cryptocurrency</span>
                        </div>
                    </div>
                    <div class="row position-relative">
                        <div>
                            <img src={crypto} alt="" className='responsive all-coins' />
                        </div>
                        <div class="col-3">
                        </div>
                        <div class="col-9 mt-5 ps-5">
                            <p className='text-start content'>
                                Cryptocurrency is a type of digital or virtual currency that uses cryptography to secure and verify transactions and to control the creation of new units. It operates independently of a central bank and is decentralized, meaning that it is not controlled by a single entity or organization.
                                <br /><br />
                                Cryptocurrencies typically use blockchain technology, which is a distributed ledger that records all transactions in a secure and transparent manner. Some popular cryptocurrencies include Bitcoin, Ethereum, Litecoin, and Ripple.
                                <br /><br />
                                One of the main features of cryptocurrencies is that they allow for peer-to-peer transactions without the need for intermediaries, such as banks or financial institutions. They also offer faster and cheaper transactions compared to traditional banking methods. However, cryptocurrencies are highly volatile and their value can fluctuate rapidly, making them a risky investment. Additionally, there have been concerns about the use of cryptocurrencies for illicit activities due to their anonymity and decentralized nature.
                                <br /><br />
                            </p>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div class="mx-auto p-2 container text-center" style={{ marginTop: "90px", marginBottom: "50px" }}>
                <div className='row' style={{height:"115px"}}>
                    <div className='col-2'>
                        <div className='mx-auto p-2 w-auto enlarge'>
                            <button className='btn enlarge' style={{ border: "none" }} onClick={() => { window.location.href = "https://test.xnftmarketplace.indexx.ai/collections/bitcoin-xnft/1" }}>
                                <img src={bitcoin} alt="Bitcoin" className='icon-btc' />
                                <p>
                                    Bitcoin XNFT
                                    {/* <br />
                                    BTC */}
                                </p>
                            </button>
                            {/* <Button onClick={() => {setEth_page(false);setBit_page(true)}}></Button> */}
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className='mx-auto p-2 w-min'>
                            <button className='btn enlarge' style={{ border: "none" }} onClick={() => {window.location.href = "https://test.xnftmarketplace.indexx.ai/collections/ethereum-xnft/2"}}>

                                <img src={ethereum} alt="Ethereum" width={"60px"} height={"60px"} className='icon-btc'/>
                                <p>
                                    Ethereum XNFT
                                    {/* <br />
                                    ETH */}
                                </p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='buy_content'>
                {bit_page ? (
                    <div className='container text-center'>
                        <div className='row text-center'>
                            <div className='col-3'>
                                <div class="card m-auto border-0 rounded-0" style={{ width: "17.74rem", backgroundColor: "#040404" }}>
                                    <div className='buy_div'><img src={bitblack} alt="" /></div>
                                    <div class="card-body">
                                        <h5 class="card-title" >${btcPrice.toFixed(2)} </h5>
                                        <p class="card-text">{btcPriceChange} ({Math.floor((btcPriceChangePer * 100)) / 100} %)</p>
                                    </div>
                                    <div className='buy_div'>
                                        <button className='buy-btc btc-black'><a className="nav-link" href="https://test.xnftmarketplace.indexx.ai/collections/bitcoin-xnft/1">Buy</a></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-3'>
                                <div class="card m-auto border-0 rounded-0" style={{ width: "17.74rem" }}>
                                    <div className='buy_div'><img src={bitpurple} alt="" /></div>
                                    <div class="card-body">
                                        <h5 class="card-title">${(btcPrice * 0.5).toFixed(2)} </h5>
                                        <p class="card-text">{btcPriceChange} ({Math.floor((btcPriceChangePer * 100)) / 100} %)</p>
                                    </div>
                                    <div className='buy_div'>
                                        <button className='buy-btc btc-purple'><a className="nav-link" href="https://test.xnftmarketplace.indexx.ai/collections/bitcoin-xnft/1">Buy</a></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-3'>
                                <div class="card m-auto border-0 rounded-0" style={{ width: "17.74rem" }}>
                                    <div className='buy_div'><img src={bitblue} alt="" /></div>
                                    <div class="card-body">
                                        <h5 class="card-title">${(btcPrice * 0.25).toFixed(2)} </h5>
                                        <p class="card-text">{btcPriceChange} ({Math.floor((btcPriceChangePer * 100)) / 100} %)</p>
                                    </div>
                                    <div className='buy_div'>
                                        <button className='buy-btc btc-blue'><a className="nav-link" href="https://test.xnftmarketplace.indexx.ai/collections/bitcoin-xnft/1">Buy</a></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row text-center' style={{ marginTop: "120px" }}>
                            <div className='col-3'>
                                <div class="card m-auto border-0 rounded-0" style={{ width: "17.74rem" }}>
                                    <div className='buy_div'><img src={bitgreen} alt="" /></div>
                                    <div class="card-body">
                                        <h5 class="card-title">${(btcPrice * 0.1).toFixed(2)} </h5>
                                        <p class="card-text">{btcPriceChange} ({Math.floor((btcPriceChangePer * 100)) / 100} %)</p>
                                    </div>
                                    <div className='buy_div'>
                                        <button className='buy-btc btc-green'><a className="nav-link" href="https://test.xnftmarketplace.indexx.ai/collections/bitcoin-xnft/1">Buy</a></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-3'>
                                <div class="card m-auto border-0 rounded-0" style={{ width: "17.74rem" }}>
                                    <div className='buy_div'><img src={bitpink} alt="" /></div>
                                    <div class="card-body">
                                        <h5 class="card-title">${(btcPrice * 0.01).toFixed(2)} </h5>
                                        <p class="card-text">{btcPriceChange} ({Math.floor((btcPriceChangePer * 100)) / 100} %)</p>
                                    </div>
                                    <div className='buy_div'>
                                        <button className='buy-btc btc-pink'><a className="nav-link" href="https://test.xnftmarketplace.indexx.ai/collections/bitcoin-xnft/1">Buy</a></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-3'>
                                <div class="card m-auto border-0 rounded-0" style={{ width: "17.74rem" }}>
                                    <div className='buy_div'><img src={bitorange} alt="" /></div>
                                    <div class="card-body">
                                        <h5 class="card-title">${(btcPrice * 0.001).toFixed(2)} </h5>
                                        <p class="card-text">{btcPriceChange} ({Math.floor((btcPriceChangePer * 100)) / 100} %)</p>
                                    </div>
                                    <div className='buy_div'>
                                        <button className='buy-btc btc-orange'><a className="nav-link" href="https://test.xnftmarketplace.indexx.ai/collections/bitcoin-xnft/1">Buy</a></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : eth_page ? (
                    <>
                        <div className='container text-center'>
                            <div className='row text-center'>
                                <div className='col-3'>
                                    <div class="card m-auto border-0 rounded-0" style={{ width: "17.74rem" }}>
                                        <div className='buy_div'><img src={ethblack} alt="" /></div>
                                        <div class="card-body">
                                            <h5 class="card-title">$ {ethPrice.toFixed(2)} </h5>
                                            <p class="card-text">{ethPriceChange} ({Math.floor((ethPriceChangePer * 100)) / 100} %)</p>
                                        </div>
                                        <div className='buy_div'>
                                            <button className='buy-btc btc-black'><a className="nav-link" href="https://test.xnftmarketplace.indexx.ai/collections/ethereum-xnft/2">Buy</a></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div class="card m-auto border-0 rounded-0" style={{ width: "17.74rem" }}>
                                        <div className='buy_div'><img src={ethpurple} alt="" /></div>
                                        <div class="card-body">
                                            <h5 class="card-title">$ {(ethPrice * 0.5).toFixed(2)} </h5>
                                            <p class="card-text">{ethPriceChange} ({Math.floor((ethPriceChangePer * 100)) / 100} %)</p>
                                        </div>
                                        <div className='buy_div'>
                                            <button className='buy-btc btc-purple'><a className="nav-link" href="https://test.xnftmarketplace.indexx.ai/collections/ethereum-xnft/2">Buy</a></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div class="card m-auto border-0 rounded-0" style={{ width: "17.74rem" }}>
                                        <div className='buy_div'><img src={ethblue} alt="" /></div>
                                        <div class="card-body">
                                            <h5 class="card-title">$ {(ethPrice * 0.25).toFixed(2)} </h5>
                                            <p class="card-text">{ethPriceChange} ({Math.floor((ethPriceChangePer * 100)) / 100} %)</p>
                                        </div>
                                        <div className='buy_div'>
                                            <button className='buy-btc btc-blue'><a className="nav-link" href="https://test.xnftmarketplace.indexx.ai/collections/ethereum-xnft/2">Buy</a></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row text-center' style={{ marginTop: "120px" }}>
                                <div className='col-3'>
                                    <div class="card m-auto border-0 rounded-0" style={{ width: "17.74rem" }}>
                                        <div className='buy_div'><img src={ethgreen} alt="" /></div>
                                        <div class="card-body">
                                            <h5 class="card-title">$ {(ethPrice * 0.1).toFixed(2)} </h5>
                                            <p class="card-text">{ethPriceChange} ({Math.floor((ethPriceChangePer * 100)) / 100} %)</p>
                                        </div>
                                        <div className='buy_div'>
                                            <button className='buy-btc btc-green'><a className="nav-link" href="https://test.xnftmarketplace.indexx.ai/collections/ethereum-xnft/2">Buy</a></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div class="card m-auto border-0 rounded-0" style={{ width: "17.74rem" }}>
                                        <div className='buy_div'><img src={ethpink} alt="" /></div>

                                        <div class="card-body">
                                            <h5 class="card-title">$ {(ethPrice * 0.01).toFixed(2)} </h5>
                                            <p class="card-text">{ethPriceChange} ({Math.floor((ethPriceChangePer * 100)) / 100} %)</p>
                                        </div>
                                        <div className='buy_div'>
                                            <button className='buy-btc btc-pink'><a className="nav-link" href="https://test.xnftmarketplace.indexx.ai/collections/ethereum-xnft/2">Buy</a></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div class="card m-auto border-0 rounded-0" style={{ width: "17.74rem" }}>
                                        <div className='buy_div'><img src={ethorange} alt="" /></div>
                                        <div class="card-body">
                                            <h5 class="card-title">$ {(ethPrice * 0.001).toFixed(2)} </h5>
                                            <p class="card-text">{ethPriceChange} ({Math.floor((ethPriceChangePer * 100)) / 100} %)</p>
                                        </div>
                                        <div className='buy_div'>
                                            <button className='buy-btc btc-orange'><a className="nav-link" href="https://test.xnftmarketplace.indexx.ai/collections/ethereum-xnft/2">Buy</a></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>) : null}
            </div> */}

            <div class="what-crypto" id='fiat-cur' style={{ marginTop: "150px" }}>
                <div>
                    <div class="row">
                        <div className='col-7 text-end'>
                            <img src={dollar} alt="" style={{ marginTop: "-53px" }} />
                            <span className='crypto'>Fiat Currency</span>
                        </div>
                        <div class="col-2" style={{ padding: 0 }}></div>
                    </div>
                    <div class="row position-relative">
                        <div>
                            <img src={dollarsigns} alt="" className='responsive symbols' />
                        </div>
                        <div class="col-9 mt-4 pe-5">
                            <p className='text-start content'>
                                Fiat currency is a type of currency that is declared by a government to be legal tender, but is not backed by a physical commodity such as gold or silver. The value of fiat currency is derived from the trust that people have in the government that issues it and its ability to maintain its value over time.
                                <br /><br />
                                Fiat currencies are used in most countries around the world and are typically managed by central banks, which control the money supply and interest rates. The supply of fiat currency can be increased or decreased by the central bank through monetary policy tools such as open market operations, reserve requirements, and discount rates.
                                <br /><br />
                                One of the advantages of fiat currency is that it is easily transferable and widely accepted as a means of payment. It is also more stable than commodity-based currencies, as it is not subject to fluctuations in the value of the underlying commodity.
                                However, fiat currency is also subject to inflation and devaluation if the government prints too much money, leading to a decrease in purchasing power. In addition, the value of fiat currency can be affected by economic and political factors, such as interest rates, inflation, and government policies.
                                <br /><br />
                            </p>
                            {/* </div> */}
                        </div>
                        <div class="col-3"></div>
                    </div>
                </div>
            </div>

            <div class="mx-auto p-2">
                <div style={{height:"110px"}} className='enlarge'>

                <button className='btn enlarge' style={{ border: "none" }} onClick={() => { window.location.href = "https://test.xnftmarketplace.indexx.ai/collections/xusd-nft/3" }}>
                                <img src={dollaricon} alt="Dollar" className='icon-usd' width={"70px"} height={"70px"}/>
                                <p>XUSD</p>
                            </button>
                {/* <img src={dollaricon} alt="" className='icon-usd' width={"60px"} height={"60px"}/>
                <p className='usd'>XUSD</p> */}
                </div>

                

                {/* <img src={dollarnote} alt="" className='my-margin' /> */}
                {/* const mystyle = {color:"white",backgroundColor: "DodgerBlue"};
                const style = {
                    color:"white"
                } */}
                <div style={{marginTop:"40px"}}>

                <div className='my-margin'  style={{display:"flex", justifyContent:"center"}}>
                    <ReactFlipCard
                        // frontStyle={styles.card}
                        // backStyle={styles.card}
                        containerStyle={{ height: "auto", width: "auto" }}
                        frontComponent={<div><img src={dollarnote} width={"657px"} height={"278px"} alt="" /></div>}
                        backComponent={<div><img src={dollarnoteback} alt="" /></div>}
                        flipTrigger="onHover"
                    />
                </div>
                </div>

                {/* <div className='my-margin'></div>
                <div>
                    <button className='buy-button dollar' ><a className="nav-link" href="https://test.xnftmarketplace.indexx.ai/collections/xusd-nft/3">Buy XUSD</a></button>
                </div>
                <div className='my-margin'></div> */}
            </div>
        </div>
    )
}

export default Home