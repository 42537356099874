import React from 'react'
import './Home.css';
import image1 from "../images/dark_images/Group 1101.svg"
import image2 from "../images/dark_images/Group 1102.svg"
import image3 from "../images/dark_images/Features.svg"
import dollarnote from '../images/dark_images/1.png'
import dollarnoteback from '../images/dollar back final 2 1.svg'
import ReactFlipCard from 'reactjs-flip-card'


const Features = () => {
  return (
    <div className='page'>
    <div>

            <img src={image1} alt="Crypto-features" style={{margin:"30px"}}/> <br/>
            <button className='buy-button dollar' style={{margin:"30px"}}><a className="nav-link" href="https://test.xnftmarketplace.indexx.ai/collections?type=cryptocurrency">Buy</a></button>
    </div>
    <div>

            <img src={image2} alt="fiat-features" style={{margin:"30px", paddingLeft:"50px"}}/><br/>
            <div>

            <div className='my-margin' style={{paddingLeft:"50px", display:"flex", justifyContent:"center"}}>
                    <ReactFlipCard
                        // frontStyle={styles.card}
                        // backStyle={styles.card}
                        containerStyle={{ height: "278px", width: "657px", display:"flex", justifyContent:"center" }}
                        frontComponent={<div><img src={dollarnote} width={"657px"} height={"278px"} alt="" /></div>}
                        backComponent={<div><img src={dollarnoteback} alt="" /></div>}
                        flipTrigger="onHover"
                    />
                </div>
                </div>
            <img src={image3} alt="fiat-features" style={{margin:"30px", paddingLeft:"50px"}}/><br/>
            <button className='buy-button dollar' style={{margin:"30px"}}><a className="nav-link" href="https://test.xnftmarketplace.indexx.ai/collections/xusd-nft/3">Buy XUSD</a></button>
  
    </div>    
    </div>
  )
}

export default Features